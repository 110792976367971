<template>
  <div class="mb-5">
    <h1 class="text-xl text-grey-light font-medium font-headers">
      New Team /
      <span class="font-bold text-grey-dark-1">General Information</span>
    </h1>
    <div class="bg-white shadow mt-3 p-3 rounded">
      <p class="font-barlow font-bold text-mdl text-grey-light mb-1.5">
        General Information
      </p>
      <p class="text-sm-2 text-grey-dark-2">
        Basic details about the people you need to manage within your team.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "NewTeam",
};
</script>
